// src/SuccessPage.js
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import "./App.css"
import './SuccessPage.css';

const SuccessPage = () => {
  const location = useLocation(); // Access state passed from the payment page
  const navigate = useNavigate();

  const { plan, userDetails } = location.state || {};

  return (
    <div className="success-page">
      <h2>Payment Successful!</h2>
      <p>🎉 Congratulations! Your payment for <strong>{plan?.name}</strong> has been successfully processed.</p>
      <p>Please check your email <strong>{userDetails?.email}</strong> for further details.</p>

      {/* YouTube Video Tutorial */}
      <div className="tutorial-section">
        <h3>Next Steps</h3>
        <p>Watch the tutorial below to learn how to set up your account and start using the service:</p>

        <div className="video-container">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/qUcsdt4Ln5o"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>

      <button className="dashboard-button" onClick={() => window.location.href = 'https://in.tradingview.com'}>
        Go to TradingView
      </button>
    </div>
  );
};

export default SuccessPage;
