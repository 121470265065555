import React, { useState } from 'react';
import axios from 'axios';
import './App.css';
import TextField from '@mui/material/TextField';
// import { ToastContainer, toast } from 'react-toastify'; // Import ToastContainer and toast
import { Toaster, toast } from 'react-hot-toast'
import 'react-toastify/dist/ReactToastify.css'; // Import Toast styles
import { countryCodes } from './countryCodes';

// Brand logo URL
const logoUrl = 'https://kredallino.com/wp-content/uploads/2024/06/Krediffy-320-x-94-px-470-x-110-px-8.svg';

const plans = [
  { id: '1', name: 'Monthly Plan', price: 999, description: 'Best for short-term traders' },
  { id: '2', name: 'Yearly Plan', price: 2999, description: 'Best for long-term traders' },
  { id: '3', name: 'Lifetime Plan', price: 1999, description: 'One-time payment, lifetime access' }
];

const API_URL = process.env.REACT_APP_API_URL || 'api';

const App = () => {
  const [selectedPlan, setSelectedPlan] = useState(plans[0]); // Default plan is the first plan
  const [userDetails, setUserDetails] = useState({
    name: '',
    email: '',
    phone: '',
    tradingViewId: '',
    country: '',
    state: '',
    postalCode: '',
    address: '',
  });
  const [countryCode, setCountryCode] = useState(countryCodes[0].code);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);

  const handleChange = (e) => {
    setUserDetails({ ...userDetails, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!userDetails.email || !userDetails.phone || !userDetails.tradingViewId) {
      toast.error('Email, phone number, and TradingView ID are required.');
      return;
    }
    if (userDetails.phone.length != 10) {
      toast.error('Invalid phone Number (10 digit)');
      return;
    }

    userDetails.phone = countryCode + userDetails.phone; // Prepend the country code to the phone number

    if (!termsAccepted) {
      toast.error('You must accept the terms and conditions.');
      return;
    }

    try {
      toast.success('Redirecting to PhonePe for payment...');

      const transactionId = `txn_${Date.now()}`;
      const amount = selectedPlan.price * 100; // Amount in paise (multiply by 100)
      const callbackUrl = `${API_URL}/phonepay/payment-callback`;

      // Send the user details along with the selected plan to the backend
      const response = await axios.post(`${API_URL}/phonepay/initiate-payment`, {
        transactionId,
        amount,
        redirectUrl: window.location.href + "success",
        callbackUrl: callbackUrl,
        userDetails: {
          ...userDetails,
          plan: selectedPlan, // Include the selected plan in the userDetails object
        }
      });

      const { success, message, data } = response.data;

      if (success && data.instrumentResponse) {
        const redirectUrl = data.instrumentResponse.redirectInfo.url;
        window.location.href = redirectUrl; // Redirect to PhonePe payment page
      } else {
        toast.error('Failed to initiate payment.');
      }
    } catch (err) {
      console.error('Payment initiation error:', err);
      toast.error('Error initiating payment.');
    }
  };

  return (
    <div className="payment-page">
      {/* Brand Logo */}
      <div className="logo-container">
        <img src={logoUrl} alt="Brand Logo" className="brand-logo" />
      </div>

      <div className="payment-container">
        <Toaster />
        {paymentSuccess ? (
          <div className="success-message">
            <h2>Payment Successful!</h2>
            <p> Congratulations 🎉! Your payment for <strong>{selectedPlan.name}</strong> has been successfully processed. Please check your email for further details.</p>
            <button className="dashboard-button" onClick={() => window.location.href = '/dashboard'}>
              Go to Dashboard
            </button>
          </div>
        ) : (
          <form className="user-form" onSubmit={handleSubmit}>
            <div className="customer-info">
              <h3>Customer Information</h3>
              <div className="form-group">
                <input
                  type="text"
                  name="name"
                  className="form-input"
                  placeholder="Full Name"
                  value={userDetails.name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group phone-email">
                <div className="phone-input-group">
                  <select
                    className="form-input country-code-dropdown"
                    value={countryCode}
                    onChange={(e) => setCountryCode(e.target.value)}
                  >
                    {countryCodes.map((country) => (
                      <option key={country.code} value={country.code}>
                        {country.name} ({country.code})
                      </option>
                    ))}
                  </select>
                  <input
                    type="tel"
                    name="phone"
                    className="form-input"
                    placeholder="Phone"
                    value={userDetails.phone}
                    onChange={handleChange}
                    required
                  />
                </div>
                <input
                  type="email"
                  name="email"
                  className="form-input email"
                  placeholder="Email"
                  value={userDetails.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group1 country-state">
                <input
                  type="text"
                  name="country"
                  className="form-input1"
                  placeholder="Country"
                  value={userDetails.country}
                  onChange={handleChange}
                  required
                />
                <input
                  type="text"
                  name="state"
                  className="form-input1"
                  placeholder="State"
                  value={userDetails.state}
                  onChange={handleChange}
                  required
                />
                <input
                  type="text"
                  name="postalCode"
                  className="form-input2"
                  placeholder="Postal Code"
                  value={userDetails.postalCode}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  name="address"
                  className="form-input"
                  placeholder="Address"
                  value={userDetails.address}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  name="tradingViewId"
                  className="form-input"
                  placeholder="TradingView ID"
                  value={userDetails.tradingViewId}
                  onChange={handleChange}
                  required
                />
              </div>

              <h3>Select a Plan</h3>
              <div className="plan-selection">
                {plans.map((plan) => (
                  <button
                    key={plan.id}
                    className={`plan-btn ${selectedPlan.id === plan.id ? 'active' : ''}`}
                    type="button"
                    onClick={() => setSelectedPlan(plan)}
                  >
                    {plan.name} - ₹{plan.price}
                  </button>
                ))}
              </div>

              {/* Terms and Conditions Checkbox */}
              <div className="form-group terms-conditions">
                <input
                  type="checkbox"
                  id="terms"
                  checked={termsAccepted}
                  onChange={() => setTermsAccepted(!termsAccepted)}
                />
                <label htmlFor="terms">
                  I accept the <a href="/terms" target="_blank">Terms and Conditions</a> and <a href="/privacy" target="_blank">Privacy Policy</a>.
                </label>
              </div>

              <button type="submit" className="form-submit-btn">Proceed to Pay</button>
            </div>
          </form>
        )}

        {!paymentSuccess && (
          <div className="order-summary">
            <div className="badge">{selectedPlan.name.split(" ")[0]}</div>
            <h3>Order Summary</h3>

            <table className="summary-table">
              <tr>
                <td><strong>Plan</strong></td>
                <td>{selectedPlan.name}</td>
              </tr>
              <tr>
                <td><strong>Description</strong></td>
                <td>{selectedPlan.description}</td>
              </tr>
              <tr>
                <td><strong>Price</strong></td>
                <td>₹{selectedPlan.price}</td>
              </tr>
            </table>

            <div className="total-section">
              <h2>Total: ₹{selectedPlan.price}</h2>
              <p>Secure Payment</p>
            </div>
          </div>

        )}

      </div>

      {/* Footer Component */}
      <footer className="footer">
        <p>
          &copy; {new Date().getFullYear()} KredMaxx Technologies FZE. All rights reserved.
          <br />
          <a href="/terms" target="_blank">Terms and Conditions</a> | <a href="/privacy" target="_blank">Privacy Policy</a>
        </p>
      </footer>
    </div>
  );
};

export default App;
